@use 'sass:math';
@import "./src/styles/Library";


.blogColumn {
    display: block;
}

.blogColumn a{
    display: block;
    white-space: pre-wrap;
}