@use 'sass:math';
@import "./src/styles/Library";
/* App */

/*
    Project customizations here
    Overides default dash styles
*/

.panel-heading_24L4s>.flex-col {
    flex-shrink: unset;
}

.form-field-repeater-item-body {
    // overflow: hidden;
}

.text-to-speech {
    .flex-inner,
    .form-field,
    .form-field-inner {
        height: 100%;
    }
}

.diff-none {

}

.diff-up:after {
    content: " \1F815";
    color: $greenHl;
    line-height: .2rem;
    font-size: 2rem;
    position: relative;
    top: .2em;
}

.diff-down:after {
    content: " \1F817";
    color: $orangeHl;
    line-height: .2rem;
    font-size: 2rem;
    position: relative;
    top: .2em;
}

.table-head-has-fields {

    .rmwc-data-table__head-cell {
        .inner {
            width: 100%;
        }
        .label{
            width: 100%;
        }

        .form-field .form-field-select__control {
            background: $panelBg;
        }
    }

}