@use 'sass:math';
@import "./src/styles/Library";


.mark {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.red {
    @extend .mark;
    background-color: red;
}

.green {
    @extend .mark;
    background-color: $secondary;
}