@use 'sass:math';
@import "./src/styles/Library";
.item {
    background-color: $light3;
    border-radius: 5px;
    text-align: center;
    padding: 4px;
    flex: 1;
}

.value {
    padding: 8px;
    background-color: $light0;
    border-radius: 5px;
    font-size: 2rem;
    font-weight: 700;
    box-shadow: 2px 2px 0px rgba($dark2, .04);
}

.label {
    color: white;
    font-weight: 600;
    padding: 4px 4px 2px;
    text-shadow: 2px 2px 0px rgba($dark2, .08);
}

.up {
    background-color: $greenHl;
}

.down {
    background-color: $orangeHl;
}

.none {
    background-color: $yellowHl;
}