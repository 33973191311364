@use 'sass:math';
@import "./src/styles/Library";
.card-grid-item :global {
    cursor: pointer;

    .flex-box {
        background: $panelBg;
        border: 2px solid $panelBdr;
    }

    .body {
        padding: 1.5rem 1.25rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .foot {
        padding: .8rem 1.25rem;
        border-top: 1px solid $panelBdr;
        background: $panelFooterBg;
    }

    .name {
        font-size: 1.25rem;
        font-weight: 600;
    }

    .change {
        text-align: right;
        flex-grow: 1;
    }

}

.card-grid-item:hover :global {

    .flex-box {
        background: $panelBg;
        border-color: $primary;
    }

    .name {
        color: $primary;
    }

}