@use 'sass:math';
@import "./src/styles/Library";


.urlWrap {
    display: flex;
    width: 100%;
    max-width: 840px;
    align-items: center;
}

.url {
    display: block;
    width: 840px;
    white-space: pre-wrap;
}

.btnKeywords{
    display: block;
}
