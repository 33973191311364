@use 'sass:math';
@import "./src/styles/Library";
.home {
    width: 1280px;
    max-width: 100%;
    margin: auto;
}

.logo img {
    max-width: 100%;
    width: 400px;
    margin: 0 auto;
    display: block;
}

.zones {
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 2rem;
    justify-content: center;
}

.zone {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100px;
    height: 100px;
    background: $panelBg;
    box-shadow: 0 0 1rem 0 rgba($panelBdr, .6);
    border-radius: 3px;
    box-sizing: border-box;
    text-align: center;
}

.zone i {
    display: block;
    text-align: center;
}

.zone span {
    display: block;
    position: absolute;
    bottom: 20px;
    color: $primaryDark;
    font-weight: 600;
    transition: color 0.2s ease;
}

.zone:hover span {
    color: $primary;
}
